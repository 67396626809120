import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { Flex, Box, VStack, IconButton } from "@chakra-ui/react"
import get from "lodash.get"
import PropTypes from "prop-types"
import { CalendarIcon } from "@chakra-ui/icons"
import {
  MdPerson,
  MdExitToApp,
  // MdReport,
  MdNote,
  // MdInsertChart,
  // MdVerifiedUser,
  MdViewList,
} from "react-icons/md"
import * as ROUTES from "../../../utils/Constants/Routes"
import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"
// import { AuthUserContext } from "../../../context/Session"
// import Sidebar from "../../atoms/Sidebar"
function Layout(props) {
  const authUser = useContext(AuthUserContext)
  const history = useHistory()
  const { children, firebase } = props
  // console.log("authUser ===>", authUser)
  return (
    <Flex
      w="100vw"
      h="100vh"
      overflow="hidden"
      position="relative"
      justify="center"
      align="center"
      className="main"
    >
      {/* {authUser && <Sidebar history={otherProps.history} />} */}

      {!history.location.pathname.includes("login") && (
        <Flex
          h="100%"
          w="60px"
          overflow="hidden"
          className="sidebar"
          borderRight="1px"
          borderColor="gray.200"
          py="10"
          px="2"
          direction="column"
          align="center"
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          <VStack
            spacing="6"
            sx={{
              "@media print": {
                display: "none",
              },
            }}
          >
            {(get(authUser, ["roles", "ADMIN"]) ||
              get(authUser, ["roles", "SUPER_ADMIN"]) ||
              get(authUser, ["roles", "PROVIDER"]) ||
              get(authUser, ["roles", "USER"])) && (
              <IconButton
                colorScheme="teal"
                aria-label="Calendario"
                icon={<CalendarIcon />}
                variant="ghost"
                _hover={{ bg: "#ebedf0" }}
                onClick={() => history.push(ROUTES.DASHBOARD)}
              />
            )}
            {!get(authUser, ["roles", "PROVIDER"]) && (
              <IconButton
                colorScheme="teal"
                aria-label="Clientes"
                icon={<MdPerson />}
                variant="ghost"
                _hover={{ bg: "#ebedf0" }}
                onClick={() => history.push(ROUTES.CLIENTS)}
              />
            )}
            {/* {(get(authUser, ["roles", "MARKETING"]) ||
              get(authUser, ["roles", "SUPER_ADMIN"])) && (
              <IconButton
                colorScheme="teal"
                aria-label="Marketing"
                icon={<MdInsertChart />}
                variant="ghost"
                _hover={{ bg: "#ebedf0" }}
                onClick={() => history.push(ROUTES.MARKETING)}
              />
            )} */}
            {/* {(get(authUser, ["roles", "ADMIN"]) ||
              get(authUser, ["roles", "SUPER_ADMIN"])) && (
              <IconButton
                colorScheme="teal"
                aria-label="Administración"
                icon={<MdVerifiedUser />}
                variant="ghost"
                _hover={{ bg: "#ebedf0" }}
                onClick={() => history.push(ROUTES.ADMINISTRATION)}
              />
            )} */}
            <IconButton
              colorScheme="teal"
              aria-label="Cierre de caja"
              icon={<MdNote />}
              variant="ghost"
              _hover={{ bg: "#ebedf0" }}
              onClick={() => {
                history.push(ROUTES.SUMMARY)
              }}
            />
            <IconButton
              colorScheme="teal"
              aria-label="Productos"
              icon={<MdViewList />}
              variant="ghost"
              _hover={{ bg: "#ebedf0" }}
              onClick={() => {
                history.push(ROUTES.PRODUCTS)
              }}
            />
            <IconButton
              colorScheme="teal"
              aria-label="Salir"
              icon={<MdExitToApp />}
              variant="ghost"
              _hover={{ bg: "#ebedf0" }}
              onClick={() => {
                firebase.doSignOut()
                history.push(ROUTES.LOGIN)
              }}
            />
          </VStack>
        </Flex>
      )}
      <Box
        w={
          history.location.pathname.includes("login")
            ? "calc(100% - 60px)"
            : "100%"
        }
        h="100%"
        className="content"
      >
        {children}
      </Box>
    </Flex>
  )
}

Layout.propTypes = {
  firebase: PropTypes.shape().isRequired,
  children: PropTypes.element.isRequired,
}

export default withFirebase(Layout)
