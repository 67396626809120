import { theme as chakraTheme } from "@chakra-ui/react"

const theme = {
  ...chakraTheme,
  fonts: {
    ...chakraTheme.fonts,
    body: "Circular Std Book",
    heading: "Circular Std Bold",
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 800,
  },
  colors: {
    ...chakraTheme.colors,
    brand: {
      green: {
        100: "#69AEAA",
        200: "#231F20",
        300: "#295C64",
      },
      gray: {
        100: "#5C5857",
      },
    },
  },
}
export default theme
