/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { compose } from "recompose"
import Select from "react-select"
import {
  Flex,
  Box,
  Grid,
  Text,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  FormErrorMessage,
  Button,
  RadioGroup,
  Checkbox,
  Stack,
  Radio,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useToast,
} from "@chakra-ui/react"
import { Formik, Form, Field, FieldArray } from "formik"
import {
  MdPhoneIphone,
  MdPerson,
  MdBusiness,
  MdRecentActors,
  MdDelete,
  MdEmail,
} from "react-icons/md"

import { withFirebase } from "../../../utils/Firebase"
import { CREATE_BILL, CREATE_TICKET } from "../../../utils/Constants/Functions"

const TAX = 0.04
const Billing = ({
  firebase,
  eventInfo,
  onBillingClose,
  types,
  providerId,
}) => {
  const toast = useToast()
  const [isGenerating, setIsGenerating] = React.useState(false)

  const getPayType = (paymentType) => {
    switch (paymentType) {
      case "01":
        return "Efectivo"
      case "02":
        return "Tarjeta"
      case "04":
        return "Sinpe"
      case "99":
        return "Otros"
      default:
        return "Otros"
    }
  }
  const getTotalsWithTax = (items, paymentType, billType) => {
    if (items && items.length > 0) {
      return items.reduce((accumulator, currentValue) => {
        let newTotal = 0
        newTotal = parseFloat(currentValue.price * currentValue.quantity)
        // if (paymentType === "01") {
        //   newTotal = parseFloat(currentValue.price * currentValue.quantity)
        //   // parseFloat(
        //   //   currentValue.price * currentValue.quantity * TAX
        //   // ).toFixed(2)
        // } else {
        //   newTotal = parseFloat(currentValue.price * currentValue.quantity)
        // }
        if (currentValue.discount > 0) {
          newTotal -= newTotal * (currentValue.discount / 100)
          if (paymentType === "01" && billType === "01") {
            newTotal += newTotal * TAX
          }
        }
        if (
          paymentType === "01" &&
          billType === "01" &&
          currentValue.discount <= 0
        ) {
          newTotal += newTotal * TAX
        }
        return accumulator + newTotal
      }, 0)
    }
    return 0
  }

  const getLineDiscount = (price, quantity, discount, paymentType) => {
    let newTotal = 0
    let totalDiscount = 0
    newTotal = parseFloat(price * quantity)
    // if (paymentType === "01") {
    //   newTotal = parseFloat(price * quantity)
    //   newTotal += newTotal * TAX
    // } else {
    //   newTotal = parseFloat(price * quantity)
    // }
    if (discount > 0) {
      totalDiscount = newTotal * (discount / 100)
    }
    return totalDiscount
  }

  return (
    <Box>
      <Box>
        <Formik
          initialValues={{
            fullName: eventInfo.client,
            legalName: "",
            email: eventInfo.clientEmail,
            legalId: eventInfo.clientLegalId,
            mobileNumber: eventInfo.clientMobileNumber,
            phoneNumber: eventInfo.clientPhoneNumber,
            legalType: "01",
            paymentType: "01",
            billType: "01",
            items: [
              {
                name: eventInfo?.reasonId,
                price: null,
                quantity: 1,
                discount: 0,
                checked: false,
              },
            ],
          }}
          validationSchema={Yup.object({
            paymentType: Yup.string().trim().required("Requerido"),
            legalType: Yup.string().required("Requerido"),
            legalId: Yup.string()
              .trim()
              .test(
                "numbers-9-10-11",
                "El formato no es válido",
                (value, context) => {
                  const validate =
                    context.parent.legalType === "01"
                      ? value &&
                        value.match(/^([1-9])\d{4}\d{4}$/) &&
                        value.match(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/) &&
                        value.match(/^\d+$/)
                      : value &&
                        value.match(/^[0-9]{11}$|^[0-9]{12}$/) &&
                        value.match(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/) &&
                        value.match(/^\d+$/)
                  return validate
                }
              )
              .required("Requerido"),
            fullName: Yup.string().required("Requerido"),
            email: Yup.string().email("Correo inválido"),
            phoneNumber: Yup.string().required("Requerido"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true)
              setIsGenerating(true)
              const payload = {
                bill: {
                  sucursal: "101",
                  punto: "00101",
                  actividad: "851101",
                  medio_pago: [values.paymentType],
                  condicion_venta: "01",
                  moneda: "CRC",
                  tipo_cambio: "1",
                  tipo_cedula: values.legalType,
                  cedula: values.legalId,
                  nombre: values.fullName,
                  nombre_comercial: values.legalName,
                  correo: values.email,
                  "correo-copia": null,
                  lineas:
                    values.items.map((i) => ({
                      codigo: "001",
                      codigoCabys: "9723000000100",
                      partidaArancelaria: null,
                      descripcion:
                        types.find((t) => t.id === i.name)?.description ||
                        "Sin descripción",
                      tipo: null,
                      cantidad: i.quantity || 1,
                      unidad: "Sp",
                      precioUnitario: i.price || 1,
                      descuento: getLineDiscount(
                        i.price,
                        i.quantity,
                        i.discount,
                        values.paymentType
                      ),
                      naturalezaDescuento:
                        i.discount > 0 ? "Descuento local" : "",
                      impuestos: {
                        "01": {
                          tarifa: values.paymentType === "01" ? "04" : "01",
                        },
                      },
                    })) || [],
                  comentarios: `Muchas gracias por su pago, recuerda solicitar una nueva cita. (OrderId: ${eventInfo.evtId})`,
                },
                info: {
                  orderId: eventInfo.evtId,
                  providerId,
                  total: getTotalsWithTax(
                    values.items,
                    values.paymentType,
                    values.billType
                  ),
                  client: values.fullName,
                },
              }

              const FUNCTION_NAME =
                values.billType === "01" ? CREATE_BILL : CREATE_TICKET

              const token = await firebase.getIdToken()
              const response = await fetch(FUNCTION_NAME, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              })
              let data = null
              if (response.ok) {
                data = await response.json()
                toast({
                  title: "Factura generada.",
                  description: `Se ha generado ${
                    values.billType === "01" ? "una factura" : "un tiquete"
                  } con consecutivo: ${data.sequence || data.documentId}`,
                  status: "success",
                  duration: values.billType === "02" ? 500 : 1500,
                  isClosable: true,
                })
              }
              onBillingClose(true, data?.sequence, data?.billId, {
                ...payload,
                billType: values.billType,
              })
              setIsGenerating(false)
            } catch (error) {
              toast({
                title: "Error.",
                description: "Ha ocurrido un error generando el documento.",
                status: "error",
                duration: 4000,
                isClosable: true,
              })
              setIsGenerating(false)
              setSubmitting(false)
            }
          }}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            isValid,
            errors,
          }) => (
            <Form>
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <Box gridColumn="1 / span 2">
                  <Field name="billType">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.billType && form.touched.billType
                        }
                      >
                        <FormLabel htmlFor="billType">
                          Tipo de factura:
                        </FormLabel>
                        <RadioGroup
                          {...field}
                          onChange={(value) => {
                            setFieldValue("billType", value.toString())
                          }}
                          id="billType"
                        >
                          <Stack direction="row">
                            <Radio id="opt-1" value="01" cursor="pointer">
                              Electrónica
                            </Radio>
                            <Radio id="opt-2" value="02" cursor="pointer">
                              Tiquete
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Field name="legalType">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.legalType && form.touched.legalType
                      }
                    >
                      <FormLabel htmlFor="legalType">
                        Tipo identificacíón:
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        onChange={(value) => {
                          setFieldValue("legalType", value.toString())
                        }}
                        id="legalType"
                      >
                        <Stack direction="row">
                          <Radio id="opt-1" value="01">
                            Física
                          </Radio>
                          <Radio id="opt-2" value="03">
                            Dimex
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  )}
                </Field>
                <Field name="legalId">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.legalId && form.touched.legalId}
                    >
                      <FormLabel htmlFor="legalId">Cédula:</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          fontSize="1.2em"
                          p="0"
                          zIndex="1"
                        >
                          <MdRecentActors color="gray.300" />
                        </InputLeftElement>
                        <Input {...field} id="legalId" placeholder="00000000" />
                      </InputGroup>

                      <FormErrorMessage>{form.errors.legalId}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="fullName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.fullName && form.touched.fullName}
                    >
                      <FormLabel htmlFor="fullName">Nombre:</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          fontSize="1.2em"
                          p="0"
                          zIndex="1"
                        >
                          <MdPerson color="gray.300" />
                        </InputLeftElement>
                        <Input {...field} id="fullName" placeholder="Nombre" />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.fullName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="legalName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.legalName && form.touched.legalName
                      }
                    >
                      <FormLabel htmlFor="legalName">
                        Nombre comercial:
                      </FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          fontSize="1.2em"
                          p="0"
                          zIndex="1"
                        >
                          <MdBusiness color="gray.300" />
                        </InputLeftElement>
                        <Input {...field} id="legalName" placeholder="Nombre" />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.legalName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel htmlFor="email">Correo:</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          fontSize="1.2em"
                          p="0"
                          zIndex="1"
                        >
                          <MdEmail color="gray.300" />
                        </InputLeftElement>
                        <Input {...field} id="email" placeholder="Correo" />
                      </InputGroup>
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="phoneNumber">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.phoneNumber && form.touched.phoneNumber
                      }
                    >
                      <FormLabel htmlFor="phoneNumber">Teléfono:</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          fontSize="1.2em"
                          p="0"
                          zIndex="1"
                        >
                          <MdPhoneIphone color="gray.300" />
                        </InputLeftElement>
                        <Input
                          {...field}
                          id="phoneNumber"
                          placeholder="Teléfono"
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.phoneNumber}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="paymentType">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.paymentType && form.touched.paymentType
                      }
                    >
                      <FormLabel htmlFor="paymentType">Tipo pago:</FormLabel>
                      <RadioGroup
                        {...field}
                        onChange={(value) => {
                          setFieldValue("paymentType", value.toString())
                        }}
                        id="paymentType"
                      >
                        <Stack direction="row">
                          <Radio id="opt-1" value="01">
                            Efectivo
                          </Radio>
                          <Radio id="opt-2" value="02">
                            Tarjeta
                          </Radio>
                          <Radio id="opt-2" value="04">
                            Sinpe
                          </Radio>
                          <Radio id="opt-2" value="99">
                            Otros
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  )}
                </Field>
                <Box>
                  <Stat>
                    <StatLabel>Total</StatLabel>
                    <StatNumber>
                      {`₡${getTotalsWithTax(
                        values.items,
                        values.paymentType,
                        values.billType
                      )}`}
                    </StatNumber>
                    <StatHelpText>
                      {getPayType(values.paymentType)}
                    </StatHelpText>
                  </Stat>
                </Box>
              </Grid>
              <Flex
                ml="30px"
                mr="70px"
                alignItems="center"
                borderBottom="1px solid #cccccc"
              >
                <Box flex={2} my={2}>
                  <Text>Motivo</Text>
                </Box>
                <Box flex={2} my={2}>
                  <Text>Precio</Text>
                </Box>
                <Box flex={1} my={2}>
                  <Text>Cantidad</Text>
                </Box>
                <Box flex={1} my={2}>
                  <Text>Descuento</Text>
                </Box>
              </Flex>
              <FieldArray
                name="items"
                render={(arrayHelpers) => (
                  <Box mx={5} my={1}>
                    {values.items && values.items.length > 0 ? (
                      <Box>
                        {values.items.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Flex key={index} my={1} alignItems="center">
                            <Box flex={2} m={2}>
                              <Field name={`items.${index}.name`}>
                                {({ field, form, meta }) => (
                                  <FormControl>
                                    <Select
                                      placeholder="Motivo"
                                      className="basic-single"
                                      classNamePrefix="select"
                                      noOptionsMessage={() =>
                                        "No hay resultados"
                                      }
                                      defaultValue={
                                        index === 0 && eventInfo
                                          ? types
                                              .map((t) => ({
                                                value: t.id,
                                                label: t.description,
                                              }))
                                              .find(
                                                (d) =>
                                                  d.value === eventInfo.reasonId
                                              )
                                          : undefined
                                      }
                                      isSearchable
                                      name={`items.${index}.name`}
                                      options={types.map((t) => ({
                                        value: t.id,
                                        label: t.description,
                                      }))}
                                      onChange={(value) => {
                                        setFieldValue(
                                          `items.${index}.name`,
                                          value?.value
                                        )

                                        setFieldValue(
                                          `items.${index}.price`,
                                          null
                                        )
                                      }}
                                    />
                                  </FormControl>
                                )}
                              </Field>
                            </Box>
                            <Box flex={2} m={2}>
                              <Field name={`items.${index}.price`}>
                                {({ field, form }) => {
                                  const type = types?.find(
                                    (t) => t.id === values?.items[index]?.name
                                  )
                                  const prices = type?.prices?.map((p) => ({
                                    value: p.value,
                                    label: `${p.description}: ₡${p.value}`,
                                  }))
                                  return (
                                    <Flex
                                      justifyContent="flex-start"
                                      alignItems="center"
                                    >
                                      <Checkbox
                                        pr="1"
                                        m="0"
                                        align="left"
                                        size="sm"
                                        isChecked={field.checked}
                                        onChange={(e) => {
                                          form.setFieldValue(
                                            field.name.replace(
                                              "price",
                                              "checked"
                                            ),
                                            e.target.checked
                                          )
                                        }}
                                      >
                                        Digitar
                                      </Checkbox>
                                      {form.values.items[index].checked ? (
                                        <FormControl>
                                          <Input
                                            {...field}
                                            defaultValue={0}
                                            id={`items.${index}.price`}
                                            placeholder="Precio"
                                            type="number"
                                          />
                                        </FormControl>
                                      ) : (
                                        <FormControl>
                                          <Select
                                            name={field.name}
                                            value={
                                              prices
                                                ? prices.find(
                                                    (option) =>
                                                      option.value ===
                                                      field.value
                                                  ) || ""
                                                : ""
                                            }
                                            onChange={(option) =>
                                              form.setFieldValue(
                                                field.name,
                                                option.value
                                              )
                                            }
                                            onBlur={field.onBlur}
                                            placeholder="Seleccione"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            noOptionsMessage={() =>
                                              "No hay resultados"
                                            }
                                            isSearchable
                                            options={prices}
                                          />
                                        </FormControl>
                                      )}
                                    </Flex>
                                  )
                                }}
                              </Field>
                            </Box>
                            <Box flex={1} m={2}>
                              <Field name={`items.${index}.quantity`}>
                                {({ field, form, meta }) => (
                                  <FormControl>
                                    <Input
                                      {...field}
                                      defaultValue={0}
                                      id={`items.${index}.quantity`}
                                      placeholder="Cantidad"
                                      type="number"
                                    />
                                  </FormControl>
                                )}
                              </Field>
                            </Box>
                            <Box flex={1} m={2}>
                              <Field name={`items.${index}.discount`}>
                                {({ field, form, meta }) => (
                                  <FormControl>
                                    <InputGroup>
                                      <InputLeftElement
                                        pointerEvents="none"
                                        p="0"
                                        zIndex="1"
                                      >
                                        <Text>%</Text>
                                      </InputLeftElement>
                                      <Input
                                        {...field}
                                        defaultValue={0}
                                        id={`items.${index}.discount`}
                                        placeholder="Descuento"
                                        type="number"
                                      />
                                    </InputGroup>
                                  </FormControl>
                                )}
                              </Field>
                            </Box>
                            <Box key={`b-${index}`}>
                              <Button
                                variant="ghost"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <MdDelete color="gray.300" />
                              </Button>
                            </Box>
                          </Flex>
                        ))}
                        <Box mt={5}>
                          <Button
                            size="xs"
                            colorScheme="teal"
                            type="button"
                            onClick={() =>
                              arrayHelpers.insert(values.items.length, {
                                name: null,
                                price: null,
                                quantity: 1,
                                discount: 0,
                              })
                            } // insert an empty string at a position
                          >
                            + Agregar un nuevo item
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        {/* show this when user has removed all friends from the list */}
                        Agregar un nuevo item
                      </button>
                    )}
                  </Box>
                )}
              />
              <Flex justifyContent="flex-end">
                <Button
                  disabled={isSubmitting || isGenerating}
                  colorScheme="teal"
                  mr={3}
                  onClick={() => onBillingClose(false)}
                >
                  Cerrar
                </Button>
                <Button
                  type="submit"
                  disabled={!dirty || isSubmitting || !isValid}
                  isLoading={isGenerating || isSubmitting}
                  colorScheme="teal"
                  variant="outline"
                >
                  Generar
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}

Billing.propTypes = {
  firebase: PropTypes.shape().isRequired,
  eventInfo: PropTypes.shape().isRequired,
  onBillingClose: PropTypes.func.isRequired,
  types: PropTypes.any.isRequired,
  providerId: PropTypes.string.isRequired,
}

const ComposedBilling = compose(withFirebase)(Billing)
export default ComposedBilling
