/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { compose } from "recompose"
import { MdSearch } from "react-icons/md"
import {
  Heading,
  Box,
  Flex,
  useTheme,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import { withFirebase } from "../../utils/Firebase"
import { withAuthorization } from "../../context/Session"
import { GET_BILLS } from "../../utils/Constants/Functions"

dayjs.extend(utc)

const Products = ({ firebase }) => {
  const theme = useTheme()
  const [types, setTypes] = useState([])
  const [search, setSearch] = useState([])

  useEffect(() => {
    const getTypes = async () => {
      const snapshot = await firebase.getTypes()
      const data = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => (a.description > b.description ? 1 : -1))
      setTypes(data)
    }
    if (firebase) {
      getTypes()
    }
  }, [firebase])

  return (
    <Box w="100%" h="100%" p={3} overflowY="scroll">
      <Heading>Productos</Heading>
      <Flex mt={5} mb={5} width="100%" justifyContent="flex-start">
        <InputGroup ml={5}>
          <InputLeftElement pointerEvents="none" p="0" zIndex="1">
            <MdSearch />
          </InputLeftElement>
          <Input
            width={500}
            placeholder="Buscar"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </InputGroup>
      </Flex>
      <Box maxH="80vh" overflowY="auto">
        <Table>
          <Thead>
            <Tr>
              <Th>Nombre</Th>
              <Th>Categoria</Th>
              <Th>Precio 1</Th>
              <Th>Precio 2</Th>
              <Th>Precio 3</Th>
            </Tr>
          </Thead>
          <Tbody>
            {types
              ?.filter(
                (t) =>
                  t?.description?.includes(search) ||
                  t?.category?.includes(search)
              )
              ?.map((type) => (
                <Tr>
                  <Td>{type.description}</Td>
                  <Td>{type?.category}</Td>
                  <Td>
                    {type?.prices?.[0]?.description} - ₡
                    <span style={{ fontWeight: 800 }}>
                      {type?.prices?.[0]?.value}
                    </span>
                  </Td>
                  <Td>
                    {type?.prices?.[1]?.description} - ₡
                    <span style={{ fontWeight: 800 }}>
                      {type?.prices?.[1]?.value}
                    </span>
                  </Td>
                  <Td>
                    {type?.prices?.[2]?.description} - ₡
                    <span style={{ fontWeight: 800 }}>
                      {type?.prices?.[2]?.value}
                    </span>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}

Products.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => authUser
const ComposedProducts = compose(
  withFirebase,
  withAuthorization(condition)
)(Products)
export default ComposedProducts
