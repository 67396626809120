/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from "react"
import {
  Box,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import { compose } from "recompose"
import PropTypes from "prop-types"
import logoCentro from "../../../static/images/logo-clinica-rivera.png"
import { withFirebase } from "../../../utils/Firebase"

const Print = ({ payload, firebase, onPrintClose }) => {
  const getTotalsWithTax = (items, withDiscount) => {
    if (items && items.length > 0) {
      return items.reduce((accumulator, currentValue) => {
        let newTotal = 0
        newTotal = parseFloat(
          currentValue.precioUnitario * currentValue.cantidad
        )
        if (currentValue.descuento > 0 && withDiscount) {
          newTotal -= currentValue.descuento
        }
        return accumulator + newTotal
      }, 0)
    }
    return 0
  }

  const getDiscountTotal = (items) => {
    if (items && items.length > 0) {
      return items.reduce((accumulator, currentValue) => {
        let newTotal = 0
        if (currentValue.descuento > 0) {
          console.log("descuento ==>", currentValue.descuento)
          newTotal +=  currentValue?.descuento;
        }
        return accumulator + newTotal
      }, 0)
    }
    return 0
  }

  const getPaymentType = () => {
    switch (payload?.bill?.medio_pago?.[0]) {
      case "01":
        return "Efectivo"
      case "02":
        return "Tarjeta"
      case "04":
        return "Sinpe"
      case "99":
        return "Otros"
      default:
        return "Sin tipo de pago"
    }
  }

  useEffect(() => {
    ;(() => {
      const beforePrint = function () {}

      const afterPrint = function () {
        if (window && onPrintClose) {
          onPrintClose()
        }
      }

      if (window.matchMedia) {
        const mediaQueryList = window.matchMedia("print")
        mediaQueryList.addListener((mql) => {
          if (mql.matches) {
            beforePrint()
          } else {
            afterPrint()
          }
        })
      }

      window.onbeforeprint = beforePrint
      window.onafterprint = afterPrint
    })()

    return () => {
      window.onbeforeprint = null
      window.onafterprint = null
    }
  }, [])

  useEffect(() => {
    if (payload && firebase) {
      setTimeout(() => {
        window.print()
      }, 1000)
    }
  }, [payload, firebase])
  return (
    <Box
      m={0}
      maxWidth="300px"
      overflow="hidden"
      padding={2}
      sx={{
        "@media print": {
          visibility: "visible",
          left: 0,
          top: 0,
        },
      }}
    >
      <Flex width="250px" justifyContent="center" alignItems="center" mb="30px">
        <Image
          width={200}
          src={logoCentro}
          alt="Logo Clinica Rivera"
          style={{ margin: "10" }}
        />
      </Flex>
      <Box textAlign="left" mb="20px">
        <Text fontSize="xs">{`Fecha: ${dayjs().format(
          "MM/DD/YYYY HH:mm:ss"
        )}`}</Text>
        <Text fontSize="xs">Teléfono: 4034-2269, Cartago</Text>
        <Text fontSize="xs">
          {`Cliente: ${
            payload?.bill?.nombre_comercial || payload?.bill?.nombre
          }`}
        </Text>
        <Text fontSize="xs">{`Forma Pago: ${getPaymentType()}`}</Text>
        <Text fontSize="xs">{`Consecutivo: ${payload?.bill?.sucursal} - ${
          payload?.sequence || payload?.billId
        }`}</Text>
      </Box>
      <Table mb="10px">
        <Thead>
          <Tr>
            <Th p="2px" fontSize="xs">
              Descripcion
            </Th>
            <Th p="2px" fontSize="xs">
              Precio
            </Th>
          </Tr>
          <Tr>
            <Th p="2px" fontSize="xs">
              Cantidad
            </Th>
            <Th p="2px" fontSize="xs">
              Descuento
            </Th>
            <Th p="2px" fontSize="xs">
              Total
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {payload?.bill?.lineas?.map((line, index) => {
            const precioTotal = line.precioUnitario * line.cantidad
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                <Tr>
                  <Td p="2px" fontSize="xs">
                    {line.descripcion}
                  </Td>
                  <Td p="2px" fontSize="xs">
                    {line.precioUnitario}
                  </Td>
                </Tr>
                <Tr>
                  <Td p="2px" fontSize="xs">
                    {line.cantidad}
                  </Td>
                  <Td p="2px" fontSize="xs">
                    {`${line.descuento}`}
                  </Td>
                  <Td p="2px" fontSize="xs">
                    {line.descuento > 0
                      ? precioTotal - line.descuento
                      : precioTotal}
                  </Td>
                </Tr>
              </Fragment>
            )
          })}
        </Tbody>
      </Table>
      <Box textAlign="left" mb="10px" pb="10px">
        <Text fontSize="xs">{`Sub Total: ${getTotalsWithTax(
          payload?.bill?.lineas,
          false
        )}`}</Text>
        <Text fontSize="xs">{`Descuento: ${getDiscountTotal(
          payload?.bill?.lineas
        )}`}</Text>
        <Text fontSize="xs">{`Total: ${getTotalsWithTax(
          payload?.bill?.lineas,
          true
        )}`}</Text>
      </Box>
      <Flex minH="20px" justifyContent="center" alignItems="center">
        <Text fontSize="xs">Gracias por su visita.</Text>
      </Flex>
    </Box>
  )
}

Print.propTypes = {
  firebase: PropTypes.shape().isRequired,
  payload: PropTypes.shape().isRequired,
  onPrintClose: PropTypes.func.isRequired,
}

// Print.defaultProps = {
//   payload: {
//     billId: "001-232434-234234234",
//     bill: {
//       sucursal: "101",
//       punto: "00101",
//       actividad: "851101",
//       medio_pago: ["01"],
//       condicion_venta: "01",
//       moneda: "CRC",
//       tipo_cambio: "1",
//       tipo_cedula: "01",
//       cedula: "304050012",
//       nombre: "Carlos Gomez Granado",
//       nombre_comercial: "",
//       correo: "charky809@gmail.com",
//       "correo-copia": null,
//       lineas: [
//         {
//           codigo: "001",
//           codigoCabys: "9723000000100",
//           partidaArancelaria: null,
//           descripcion: "Consulta Pruebas",
//           tipo: null,
//           cantidad: 2,
//           unidad: "Sp",
//           precioUnitario: 1000,
//           descuento: 0,
//           naturalezaDescuento: "",
//           impuestos: {
//             "01": {
//               tarifa: "04",
//             },
//           },
//         },
//       ],
//       comentarios:
//         "Muchas gracias por su pago, recuerda solicitar una nueva cita. (OrderId: IYeWrsgGlKekVn3MSCBa)",
//     },
//     info: {
//       orderId: "IYeWrsgGlKekVn3MSCBa",
//       providerId: "WoRGmB9RIfd2tN9r0YqW",
//       total: 1000,
//       client: "Carlos Gomez Granado",
//     },
//   },
// }

const ComposedPrint = compose(withFirebase)(Print)
export default ComposedPrint
