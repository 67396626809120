/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import { CSVLink, CSVDownload } from "react-csv"
import dayjs from "dayjs"
import es from "date-fns/locale/es"
import utc from "dayjs/plugin/utc"
import { compose } from "recompose"
import {
  Heading,
  Box,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useTheme,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import { withFirebase } from "../../utils/Firebase"
import { withAuthorization } from "../../context/Session"
import { GET_BILLS } from "../../utils/Constants/Functions"
import "react-datepicker/dist/react-datepicker.css"

dayjs.extend(utc)

registerLocale("es", es)

const headers = [
  { label: "Cliente", key: "client" },
  { label: "Fecha", key: "date" },
  { label: "Consecutivo", key: "id" },
  { label: "Tipo de Pago", key: "paymentType" },
  { label: "Total", key: "total" },
]

const Summary = ({ firebase }) => {
  const theme = useTheme()
  const [billsData, setBillsData] = useState([])
  const [csvData, setCsvData] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const getPaymentType = (paymentType) => {
    switch (paymentType) {
      case "01":
        return "Efectivo"
      case "02":
        return "Tarjeta"
      case "04":
        return "Sinpe"
      case "99":
        return "Otros"
      default:
        return "Sin tipo de pago"
    }
  }

  useEffect(() => {
    const getBills = async () => {
      try {
        const token = await firebase.getIdToken()
        const response = await fetch(GET_BILLS, {
          method: "POST",
          body: JSON.stringify({
            dateStart: dayjs().startOf("day").valueOf(),
            dateEnd: dayjs().endOf("day").valueOf(),
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        if (response.ok) {
          const data = await response.json()
          setBillsData(data)

          const csvToExportData = data.bills.map((bill) => ({
            client: bill.client,
            date: dayjs(bill.date).format("DD/MM/YYYY hh:mm:ss a"),
            id: bill.id,
            paymentType: getPaymentType(bill.paymentType),
            total: bill.total,
          }))

          setCsvData(csvToExportData)
        }
      } catch (error) {
        console.log("error ==>", error)
      }
    }

    if (firebase) {
      getBills()
    }
  }, [firebase, startDate, endDate])

  return (
    <Box
      w="100%"
      h="100%"
      p={3}
      overflowY="scroll"
      sx={{
        "@media print": {
          visibility: "visible",
        },
      }}
    >
      <Heading
        sx={{
          "@media print": {
            visibility: "visible",
          },
        }}
      >{`DETALLE DE CAJA (${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(
        endDate
      ).format("DD/MM/YYYY")})`}</Heading>
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        padding={15}
        mt="20px"
        sx={{
          "@media print": {
            visibility: "hidden",
          },
          ".custom-date": {
            border: "1px solid #ccc",
            width: "150px",
            marginRight: "10px",
            padding: "2px 5px",
            borderRadius: "5px",
          },
        }}
      >
        <Text size="m" mr="5px">
          Seleccione el rango de fechas:
        </Text>
        <DatePicker
          showIcon
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          className="custom-date"
          locale="es"
          dateFormat="dd/MM/yyyy"
        />
        <DatePicker
          showIcon
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          className="custom-date"
          locale="es"
          dateFormat="dd/MM/yyyy"
        />
        <CSVLink
          filename={`Detalle facturas - ${dayjs(startDate).format(
            "DD/MM/YYYY"
          )} al ${dayjs(endDate).format("DD/MM/YYYY")}`}
          data={csvData}
          headers={headers}
        >
          <Text fontWeight="bold" textDecoration="underline">
            Descargar
          </Text>
        </CSVLink>
      </Flex>
      <Flex
        mt={10}
        justifyContent="space-between"
        alignItems="center"
        padding={15}
        wrap="wrap"
        maxW={theme.breakpoints.lg}
        sx={{
          "@media print": {
            visibility: "visible",
          },
        }}
      >
        <Box
          textAlign="left"
          border="1px solid #ccc"
          borderRadius="10%"
          padding={7}
          margin={1}
        >
          <Stat>
            <StatLabel>Efectivo</StatLabel>
            <StatNumber>{`₡ ${
              billsData?.summaryByPaymentType?.["01"]?.total || 0
            }`}</StatNumber>
            <StatHelpText>{`${
              billsData?.summaryByPaymentType?.["01"]?.docs || 0
            } Documentos`}</StatHelpText>
          </Stat>
        </Box>
        <Box
          textAlign="left"
          border="1px solid #ccc"
          borderRadius="10%"
          padding={7}
          margin={1}
        >
          <Stat>
            <StatLabel>Tarjeta</StatLabel>
            <StatNumber>{`₡ ${
              billsData?.summaryByPaymentType?.["02"]?.total || 0
            }`}</StatNumber>
            <StatHelpText>{`${
              billsData?.summaryByPaymentType?.["02"]?.docs || 0
            } Documentos`}</StatHelpText>
          </Stat>
        </Box>
        <Box
          textAlign="left"
          border="1px solid #ccc"
          borderRadius="10%"
          padding={7}
          margin={1}
        >
          <Stat>
            <StatLabel>Sinpe</StatLabel>
            <StatNumber>{`₡ ${
              billsData?.summaryByPaymentType?.["04"]?.total || 0
            }`}</StatNumber>
            <StatHelpText>{`${
              billsData?.summaryByPaymentType?.["04"]?.docs || 0
            } Documentos`}</StatHelpText>
          </Stat>
        </Box>
        <Box
          textAlign="left"
          border="1px solid #ccc"
          borderRadius="10%"
          padding={7}
          margin={1}
        >
          <Stat>
            <StatLabel>Otros</StatLabel>
            <StatNumber>{`₡ ${
              billsData?.summaryByPaymentType?.["99"]?.total || 0
            }`}</StatNumber>
            <StatHelpText>{`${
              billsData?.summaryByPaymentType?.["99"]?.docs || 0
            } Documentos`}</StatHelpText>
          </Stat>
        </Box>
        <Box
          textAlign="left"
          border="1px solid #ccc"
          borderRadius="10%"
          padding={7}
          margin={1}
        >
          <Stat>
            <StatLabel>Anuladas</StatLabel>
            <StatNumber>{`₡ ${billsData?.voidBillsTotal || 0}`}</StatNumber>
            <StatHelpText>{`${
              billsData?.voidBills || 0
            } Documentos`}</StatHelpText>
          </Stat>
        </Box>
      </Flex>
      <Box
        sx={{
          "@media print": {
            visibility: "visible",
          },
        }}
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Factura</Th>
              <Th>Cliente</Th>
              <Th>Fecha</Th>
              <Th>Forma de pago</Th>
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {billsData?.bills?.map((bill) => (
              <Tr>
                <Td>{bill.id}</Td>
                <Td>{bill.client}</Td>
                <Td>{dayjs(bill.date).format("DD/MM/YYYY hh:mm:ss a")}</Td>
                <Td>{getPaymentType(bill.paymentType)}</Td>
                <Td>{`₡${bill.total}`}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}

Summary.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => authUser
const ComposedSummary = compose(
  withFirebase,
  withAuthorization(condition)
)(Summary)
export default ComposedSummary
